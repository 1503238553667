<template>
  <div>
    <div class="md-layout">
      <div class="md-layout-item">
        <div class="btn-row">
          <md-button class="md-success md-size-20 md-middle-size-100" @click="newItem">
            {{ $t('button.add') }}
          </md-button>
        </div>
        <md-card>
          <md-card-header class="md-card-header-icon md-card-header-green">
            <div class="card-icon">
              <md-icon>work</md-icon>
            </div>
            <h4 class="title">{{ $t('label.roads') }}</h4>
          </md-card-header>
          <md-card-content>
            <md-table
                :value="queriedData"
                :md-sort.sync="currentSort"
                :md-sort-order.sync="currentSortOrder"
                :md-sort-fn="customSort"
                class="paginated-table table-striped table-hover"
            >
              <md-table-toolbar>
                <md-field>
                  <label for="pages">{{ $t('label.per_page') }}</label>
                  <md-select v-model="pagination.perPage" name="pages">
                    <md-option
                        v-for="item in pagination.perPageOptions"
                        :key="item"
                        :label="item"
                        :value="item"
                    >
                      {{ item }}
                    </md-option>
                  </md-select>
                </md-field>

                <md-field>
                  <md-input
                      type="search"
                      class="mb-3"
                      clearable
                      style="width: 200px"
                      :placeholder="$t('label.search_records')"
                      v-model="searchQuery"
                  ></md-input>
                </md-field>
              </md-table-toolbar>

              <md-table-row slot="md-table-row" slot-scope="{item}">
                <md-table-cell :md-label="$t('road.road_id')">
                  {{ item.road_id }}
                </md-table-cell>

                <md-table-cell :md-label="$t('road.name')">
                  {{ item.road_name }}
                </md-table-cell>

                <md-table-cell :md-label="$t('road.category')">
                  {{ item.road_category }}
                </md-table-cell>
                <md-table-cell :md-label="$t('road.class')">
                  {{ item.road_class }}
                </md-table-cell>
                <md-table-cell
                    class="btn-cont"
                    :md-label="$t('tables.actions')"
                >
                  <div class="cell-actions">
                    <md-button
                        class="md-raised md-sm md-primary"
                        @click.stop.prevent="onEditRow(item)"
                    >
                      {{ $t('button.edit') }}
                    </md-button>
                    <md-button
                        class="md-raised md-sm md-danger"
                        @click.stop.prevent="onDeleteRow(item)"
                    >
                      {{ $t('button.delete') }}
                    </md-button>
                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>
            <div class="footer-table md-table">
              <table>
                <tfoot>
                <tr>
                  <th
                      v-for="item in footerTable"
                      :key="item.name"
                      class="md-table-head"
                  >
                    <div
                        class="md-table-head-container md-ripple md-disabled"
                    >
                      <div class="md-table-head-label">{{ item }}</div>
                    </div>
                  </th>
                </tr>
                </tfoot>
              </table>
            </div>
          </md-card-content>
          <md-card-actions md-alignment="space-between">
            <div class>
              <p class="card-category">
                {{
                  $t('label.showing_from_to_of_entries', {
                    from: to === 0 ? 0 : from + 1,
                    to: to,
                    total
                  })
                }}
              </p>
            </div>
            <pagination
                class="pagination-no-border pagination-success"
                v-model="pagination.currentPage"
                :per-page="pagination.perPage"
                :total="total"
            ></pagination>
          </md-card-actions>
        </md-card>
      </div>
    </div>
  </div>
</template>

<script>

import {Pagination} from '@/components'
import Swal from "sweetalert2";
// import {SlideYDownTransition} from 'vue2-transitions'
// import Fuse from 'fuse.js'

export default {
  name: "maintenance_planning",
  components: {
    Pagination
  },
  data() {
    return {
      domain: this.$route.params.domain,
      roadList: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      currentSort: 'key',
      currentSortOrder: 'asc',
      searchQuery: '',
      propsToSearch: ['key'],
      searchedData: [],
      fuseSearch: null,
      footerTable: []
    }
  },
  filters: {
    toDate: function (value) {
      return value.substring(0, 10)
    }
  },
  async beforeRouteUpdate(to, from, next) {
    await this.refreshListData()
    next()
  },
  async created() {
    await this.refreshListData()
  },
  methods: {
    async refreshListData() {
      console.log("refresh list data")
      this.roadList = await this.$store.dispatch("LOAD_ROAD_LIST")
    },
    newItem() {
      this.$router.push({name: "road-add"})
    },
    onEditRow(item) {
      this.$router.push({name: "road-upd", params: {road_id: item.road_id}})
    },

    onDeleteRow(item) {
      const alert = {
        icon: 'success',
        text: this.$t(`user.road_was_deleted`),
        footer: ''
      }

      Swal.fire({
        title: `${this.$t("label.are_you_sure")}`,
        text: `${this.$t("label.do_you_want_to_delete_road")} ${item.road_id}`,
        type: "warning",
        showCancelButton: true,
        confirmButtonClass: "md-button md-danger btn-fill",
        cancelButtonClass: "md-button md-default btn-fill",
        confirmButtonText: `${this.$t("label.yes_delete_it")}`,
        buttonsStyling: false
      }).then(async result => {
        if (result.value) {
          try {
            await this.$store.dispatch("DELETE_ROAD", item.road_id)
            const ind = this.roadList.findIndex(road => road.road_id === item.road_id)
            this.roadList.splice(ind, 1)
            Swal.fire({
              title: this.$t("label.deleted"),
              text: this.$t("label.road_was_deleted"),
              type: "success",
              confirmButtonClass: "md-button md-success btn-fill",
              buttonsStyling: false
            });
          } catch (err) {
            alert.type = 'error'
            alert.text = this.$t(`label.road_was_not_deleted`)
            alert.footer = this.$t(err.message ? err.message : err)
            Swal.fire(alert)
            //throw err
          }
        }
      });


    },
    customSort(value) {
      return value.sort((a, b) => {
        const sortBy = this.currentSort
        if (this.currentSortOrder === 'asc') {
          return a[sortBy]
              .toString()
              .localeCompare(b[sortBy].toString(), undefined, {numeric: true})
        }
        return b[sortBy]
            .toString()
            .localeCompare(a[sortBy].toString(), undefined, {numeric: true})
      })
    }
  },
  computed: {
    queriedData() {
      const result = !this.searchQuery
          ? this.tableData
          : this.searchedData
              ? this.searchedData
              : []
      return result.slice(this.from, this.to)
    },
    to() {
      let highBound = this.from + this.pagination.perPage
      if (this.total < highBound) {
        highBound = this.total
      }
      return highBound
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1)
    },
    total() {
      return this.searchQuery ? this.searchedData.length : this.tableData.length
    },
    tableData() {
      return this.roadList
    }
  },
  watch: {
    /**
     * Searches through the table data by a given query.
     * NOTE: If you have a lot of data, it's recommended to do the search on the Server Side and only display the results here.
     * @param value of the query
     */
    searchQuery(value) {
      let result = this.tableData
      const regex = new RegExp(`${value}`, 'i')
      if (value !== '') {
        result = this.tableData.filter((row) => {
          return Object.values(row).reduce((incl, elem) => incl || regex.test(elem), false)
        }) //this.fuseSearch.search(this.searchQuery)
      }
      this.searchedData = result
    }
    // bridges_headers() {
    // }
  }
}

</script>

<style lang="scss" scoped>

.md-table-head-label {
  height: auto;
  max-width: 170px;
  padding-right: 32px;
  padding-left: 2px;
  display: inline-block;
  position: relative;
  line-height: 20px;
}

.md-table-cell-container {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &:hover {
    overflow: visible;
    white-space: normal;
    height: auto; /* just added this line */
  }
}

.wrapped-cell {
  width: 170px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

}

.cost-overhead {
  color: red;
}

.cost-good {
  color: green;
}

.cell-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  .md-button {
    margin: 3px 0;
    min-width: 100px;
  }
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;

  button {
    margin-left: 10px
  }
}
</style>
